import { API_BASE_URL } from "src/constants";
import { get, post } from "src/core/utils/api";
import { IntValue } from "src/types/common";

const createAdministrationService = () => {
  const refreshPriceConfigurators = async (
    accessToken: string,
    value: string
  ): Promise<void> => {
    await post<void>(
      accessToken,
      API_BASE_URL + `/v1/administration/resync-data`,
      { value: value }
    );
  };

  const resetIsSyncingPriceConfigurators = async (
    accessToken: string,
    value: string
  ): Promise<void> => {
    await post<void>(
      accessToken,
      API_BASE_URL + `/v1/administration/price-configurators/syncing/reset`,
      { value: value }
    );
  };

  const fetchIsSyncingPriceConfigurators = async (
    accessToken: string
  ): Promise<IntValue[]> => {
    const response = await get<IntValue[]>(
      accessToken,
      API_BASE_URL + `/v1/administration/price-configurators/syncing`
    );

    return response;
  };

  const refreshGlobalManualAdjustmentAlerts = async (
    accessToken: string
  ): Promise<void> => {
    await post<void>(
      accessToken,
      API_BASE_URL + `/v1/administration/gma/alerts/refresh`,
      {}
    );
  };

  const reloadGlobalDataCache = async (accessToken: string): Promise<void> => {
    await post<void>(
      accessToken,
      API_BASE_URL + `/v1/administration/data/cache/reload`,
      {}
    );
  };

  return {
    refreshPriceConfigurators,
    resetIsSyncingPriceConfigurators,
    fetchIsSyncingPriceConfigurators,
    reloadGlobalDataCache,
    refreshGlobalManualAdjustmentAlerts,
  };
};

export const administrationService = createAdministrationService();
