import { Divider, Select, Skeleton, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputLabel } from "src/core/components/InputLabel";
import { useAuth } from "src/core/hooks/useAuth";
import { dataLookupService } from "src/core/services/priceConfiguratorServices/dataLookupService";
import { inputStyleBase } from "src/core/styles/common";
import { ModelSkuTable } from "src/modules/PriceConfiguratorManager/components/DataSectionAliasHandler/components/ModelSkuSelector/components/ModelSkuTable";
import { ModelSkuData, ModelSkuMasterData } from "src/types/priceConfigurator";

type Props = {
  priceConfiguratorId: number;
  dataSectionId: number;
  aliasId: number;
  modelNumber?: string;
  modelRelease?: string;
  modelSkuData?: ModelSkuData;
  setModelSkuData: (data: ModelSkuData) => Promise<void>;
  isPackaging?: boolean;
  onIsPackagingChange: (ispackaging: boolean) => Promise<void>;
  disabled?: boolean;
};

export const ModelSkuSelector: React.FC<Props> = (props: Props) => {
  const {
    modelNumber,
    modelRelease,
    isPackaging,
    modelSkuData,
    setModelSkuData,
    onIsPackagingChange,
    priceConfiguratorId,
    dataSectionId,
    aliasId,
    disabled,
  } = props;

  const [loading, setLoading] = useState(true);

  const [skuData, setSkuData] = useState<ModelSkuMasterData>({
    averageTotalCost: 0,
    aliasDataSkus: [],
  });

  const { accessToken, success } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (modelNumber && modelRelease && aliasId && success) {
        setLoading(true);
        setSkuData({
          averageTotalCost: 0,
          aliasDataSkus: [],
        });

        const response = await dataLookupService.fetchModelSkuMasterData(
          accessToken,
          priceConfiguratorId,
          dataSectionId,
          aliasId,
          modelNumber,
          modelRelease,
          isPackaging
        );

        setSkuData(response);
      }

      setLoading(false);
    })();
  }, [
    accessToken,
    aliasId,
    dataSectionId,
    isPackaging,
    modelNumber,
    modelRelease,
    priceConfiguratorId,
    success,
  ]);

  useEffect(() => {
    (async () => {
      if (
        !modelSkuData &&
        skuData?.aliasDataSkus &&
        skuData?.aliasDataSkus.length > 0
      ) {
        const selectedValue = skuData?.aliasDataSkus.find(
          (item: ModelSkuData) => item.isPreSelected
        );

        if (selectedValue) {
          await setModelSkuData(selectedValue);
        }
      }
    })();
  }, [modelSkuData, setModelSkuData, skuData?.aliasDataSkus]);

  return (
    <React.Fragment>
      {modelNumber && modelRelease && (
        <Space direction="vertical" size={0} style={{ width: "100%" }}>
          <Divider orientation="left" plain style={{ border: "gray" }}>
            {t("dataSection.selectSkuTitle")}
          </Divider>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Space>
              <InputLabel label={t("dropDown.filterSku")} width={90} />
              <Select
                disabled={disabled}
                value={isPackaging}
                options={[
                  {
                    label: t("label.packaging"),
                    value: true,
                    key: "with-packaging",
                  },
                  {
                    label: t("label.noPackaging"),
                    value: false,
                    key: "with-no-packaging",
                  },
                ]}
                onChange={async (value: boolean) => {
                  setSkuData({
                    averageTotalCost: 0,
                    aliasDataSkus: [],
                  });

                  await onIsPackagingChange(value);
                }}
                style={inputStyleBase}
              />
            </Space>
            <Skeleton
              loading={loading}
              style={{ minHeight: 100, minWidth: 200 }}
              active
            >
              <ModelSkuTable
                disabled={disabled}
                data={skuData}
                onSelect={async (selectedSkuData: ModelSkuData) => {
                  await setModelSkuData(selectedSkuData);
                }}
                selectedSkuValue={modelSkuData?.modelSkuKey}
              />
            </Skeleton>
          </Space>
        </Space>
      )}
    </React.Fragment>
  );
};
