import { Col, Flex, Row, Space, Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ButtonLink } from "src/core/components/ButtonLink";
import { useAuth } from "src/core/hooks/useAuth";
import { lensesService } from "src/core/services/priceConfiguratorServices/lensesService";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { currentPriceConfiguratorState } from "src/recoil/atoms";
import { LensesDataItem } from "src/types/priceConfigurator";

type Props = {
  data: LensesDataItem[];
  dataRefreshing?: boolean;
};

const { Text } = Typography;

export const LensesDataItemsTable = (props: Props) => {
  const { data, dataRefreshing } = props;

  const [tableLoading, setTableLoading] = useState(true);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<LensesDataItem[]>([]);

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );

  const { accessToken, success, authLoaded } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const preselectedItems: LensesDataItem[] = data.filter(
        (x: LensesDataItem) => x.isSelected
      );

      const preselectedItemKeys: number[] = preselectedItems.map(
        (y: LensesDataItem) => {
          return y.id;
        }
      );

      setSelectedRowKeys(preselectedItemKeys);
      setSelectedRows(preselectedItems);
    })();
  }, [data]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        setTableLoading(false);
      }
    })();
  }, [authLoaded, success]);

  const columns: ColumnsType<LensesDataItem> = [
    {
      title: t("tableColumn.model"),
      key: "l_model_column",
      align: "center",
      width: 140,
      render: (item: LensesDataItem) => item.model,
    },
    {
      title: t("tableColumn.madeIn"),
      key: "plant_material_column",
      align: "center",
      width: 90,
      render: (item: LensesDataItem) => item.plant,
    },
    {
      title: t("tableColumn.release"),
      key: "l_release_column",
      align: "center",
      width: 140,
      render: (item: LensesDataItem) => item.release,
    },
    {
      title: t("tableColumn.lensCode"),
      key: "l_lens_code_column",
      align: "center",
      width: 140,
      render: (item: LensesDataItem) => item.lensCode,
    },
    {
      title: t("tableColumn.lensMaterial"),
      key: "l_lens_material_column",
      align: "center",
      width: 140,
      render: (item: LensesDataItem) => item.lensMaterial,
    },
    {
      title: t("tableColumn.lensType"),
      key: "l_lens_type_column",
      align: "center",
      width: 140,
      render: (item: LensesDataItem) => item.lensType,
    },
    {
      title: t("tableColumn.lensDcAvg"),
      key: "l_lens_dc_avg_column",
      align: "center",
      width: 140,
      render: (item: LensesDataItem) => (
        <ValueWithCurrency
          value={item.lensDc}
          currencyCode={item.currencyCode}
        />
      ),
    },
    {
      title: t("tableColumn.lensFcAvg"),
      key: "l_lens_fc_avg_column",
      align: "center",
      width: 140,
      render: (item: LensesDataItem) => (
        <ValueWithCurrency
          value={item.lensFc}
          currencyCode={item.currencyCode}
        />
      ),
    },
    Table.SELECTION_COLUMN,
  ];

  const rowSelection = {
    onChange: (selectedRowKey: React.Key[], selectedRows: LensesDataItem[]) => {
      setSelectedRowKeys(selectedRowKey);
      setSelectedRows(selectedRows);
    },
    onSelect: async (item: LensesDataItem, selected: boolean) => {
      if (selected) {
        setTableLoading(true);

        await lensesService.selectModelLensesOpticalDataItem(
          accessToken,
          currentPriceConfigurator.id,
          currentPriceConfigurator.modelNumber,
          item.id
        );

        setTableLoading(false);
      }
    },
  };

  const onClear = async () => {
    if (selectedRows.length > 0) {
      setTableLoading(true);

      await lensesService.unselectModelLensesOpticalDataItem(
        accessToken,
        currentPriceConfigurator.id,
        selectedRows[0].id
      );

      setSelectedRowKeys([]);
      setSelectedRows([]);

      setTableLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Title style={{ marginBottom: 5, display: "block" }} level={5}>
        {t("label.costSummary")}
      </Title>
      <Row style={{ height: 60, border: "2px solid", marginBottom: 25 }}>
        <Col span={8}>
          <Space
            align="center"
            style={{
              justifyContent: "center",
              width: "100%",
              height: 58,
              borderRight: "1px solid",
            }}
            className="ignore-full-width-space"
          >
            <Text>
              {t("label.directCost")}:{" "}
              <ValueWithCurrency
                value={selectedRows[0]?.lensDc}
                currencyCode={selectedRows[0]?.currencyCode}
                showZeroIfNull={true}
                showForceZeroIfZero={true}
              />
            </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            align="center"
            style={{
              justifyContent: "center",
              width: "100%",
              height: 58,
              borderRight: "1px solid",
            }}
            className="ignore-full-width-space"
          >
            <Text>
              {t("label.totalOvh")}:{" "}
              <ValueWithCurrency
                value={
                  selectedRows[0]
                    ? Math.abs(
                        (selectedRows[0]?.lensFc ?? 0) -
                          (selectedRows[0]?.lensDc ?? 0)
                      )
                    : 0
                }
                currencyCode={selectedRows[0]?.currencyCode}
                showZeroIfNull={true}
                showForceZeroIfZero={true}
              />
            </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            align="center"
            style={{ justifyContent: "center", width: "100%", height: 58 }}
            className="ignore-full-width-space"
          >
            <Text>
              {t("label.fullCost")}:{" "}
              <ValueWithCurrency
                value={selectedRows[0]?.lensFc}
                currencyCode={selectedRows[0]?.currencyCode}
                showZeroIfNull={true}
                showForceZeroIfZero={true}
              />
            </Text>
          </Space>
        </Col>
      </Row>
      <Flex justify="space-between">
        <Title style={{ marginBottom: 5, display: "block" }} level={5}>
          {t("dataSection.lensTableIntro")}
        </Title>
        {selectedRows.length > 0 && (
          <ButtonLink
            label={t("button.clear")}
            onClick={onClear}
            width={60}
            disabled={tableLoading || currentPriceConfigurator?.isReadOnlyMode}
          />
        )}
      </Flex>

      <Table
        columns={columns}
        loading={tableLoading || dataRefreshing}
        dataSource={data}
        pagination={false}
        rowKey={({ id }) => id}
        scroll={{ x: "max-content" }}
        bordered={true}
        rowSelection={{
          ...rowSelection,
          type: "radio",
          selectedRowKeys: selectedRowKeys,
          hideSelectAll: true,
          getCheckboxProps: () => {
            return {
              disabled: currentPriceConfigurator?.isReadOnlyMode,
            };
          },
        }}
        size={"small"}
      />
    </React.Fragment>
  );
};
