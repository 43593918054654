import { Flex, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppFeature } from "src/core/hooks/useAppFeature";
import { AppFeatureEnum } from "src/enums";
import { AdminPage } from "src/modules/AdminPage/AdminPage";
import { ControlIsSyncingFeature } from "src/modules/AdministrationManager/components/ControlIsSyncingFeature/ControlIsSyncingFeature";
import { RefreshGlobalManualAdjustmentAlertsFeature } from "src/modules/AdministrationManager/components/RefreshGlobalManualAdjustmentAlertsFeature/RefreshGlobalManualAdjustmentAlertsFeature";
import { RefreshPriceConfiguratorsFeature } from "src/modules/AdministrationManager/components/RefreshPriceConfiguratorsFeature/RefreshPriceConfiguratorsFeature";
import { ReloadGlobalDataCacheFeature } from "src/modules/AdministrationManager/components/ReloadGlobalDataCacheFeature/ReloadGlobalDataCacheFeature";

export const AdministrationManager: React.FC = () => {
  useAppFeature(AppFeatureEnum.ADMINISTRATIONFEATURE);

  const { t } = useTranslation("administration");

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => setLoading(false), []);

  return (
    <AdminPage
      activePage="administration"
      pageTitle={t("title.administrationTitle")}
    >
      <Skeleton loading={loading} active>
        <Flex gap={25} style={{ maxWidth: 900 }} wrap>
          <RefreshPriceConfiguratorsFeature />
          <ControlIsSyncingFeature />
          <RefreshGlobalManualAdjustmentAlertsFeature />
          <ReloadGlobalDataCacheFeature />
        </Flex>
      </Skeleton>
    </AdminPage>
  );
};
