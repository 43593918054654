import { useCallback } from "react";
import { useAuth } from "src/core/hooks/useAuth";
import { useOnError } from "src/core/hooks/useOnError";
import { globalManualAdjustmentsService } from "src/core/services/globalManualAdjustments/globalManualAdjustmentsService";

export function useDataManageMethods<T extends { id: number }>(
  dataItems: T[] | undefined,
  setDataItems: (items: T[]) => void,
  setSavingUnsavedRecordId: (id?: number) => void,
  setUnsavedRecord: (record?: T) => void,
  unsavedRecord: T | undefined,
  setTableLoading: (loading: boolean) => void,
  clearFilter?: () => void
) {
  const { accessToken } = useAuth();
  const { onError } = useOnError();

  const reloadData = useCallback(async () => {
    setTableLoading(true);

    const response = (await globalManualAdjustmentsService.getRecords(
      accessToken
    )) as T[];

    clearFilter?.();

    setDataItems(response);
    setTableLoading(false);
  }, [accessToken, clearFilter, setDataItems, setTableLoading]);

  const saveRecord = useCallback(
    async (
      record: T,
      property: string,
      value: string | number | undefined | boolean,
      index: number
    ) => {
      if (dataItems) {
        const recordUpdateData = { ...record, [property]: value };

        if (record.id > 0) {
          // DB record update
          setSavingUnsavedRecordId(record.id);

          let byResponseUpdatedDataItems: T[] = [...dataItems];

          byResponseUpdatedDataItems.splice(index, 1, recordUpdateData);
          setDataItems(byResponseUpdatedDataItems);

          await globalManualAdjustmentsService
            .saveRecord(accessToken, recordUpdateData)
            .catch(() => {
              onError();
            });

          const response = (await globalManualAdjustmentsService.getRecord(
            accessToken,
            record.id
          )) as T;

          byResponseUpdatedDataItems = [...dataItems];

          byResponseUpdatedDataItems.splice(index, 1, response);
          setDataItems(byResponseUpdatedDataItems);

          setSavingUnsavedRecordId(undefined);
        } else {
          if (unsavedRecord) {
            setUnsavedRecord({ ...unsavedRecord, [property]: value });
          }
        }
      }
    },
    [
      accessToken,
      dataItems,
      onError,
      setDataItems,
      setSavingUnsavedRecordId,
      setUnsavedRecord,
      unsavedRecord,
    ]
  );

  const deleteRecord = useCallback(
    async (record: T): Promise<void> => {
      if (dataItems) {
        setSavingUnsavedRecordId(record.id);
        setTableLoading(true);

        await globalManualAdjustmentsService
          .deleteRecord(accessToken, record.id)
          .catch(() => {
            onError();
          });

        setDataItems(dataItems.filter((item) => item.id !== record.id));

        setSavingUnsavedRecordId(undefined);
        setTableLoading(false);

        await reloadData();
      }
    },
    [
      accessToken,
      dataItems,
      onError,
      reloadData,
      setDataItems,
      setSavingUnsavedRecordId,
      setTableLoading,
    ]
  );

  const duplicateRecord = useCallback(
    async (record: T): Promise<void> => {
      if (dataItems) {
        setSavingUnsavedRecordId(record.id);
        setTableLoading(true);

        await globalManualAdjustmentsService
          .duplicateRecord(accessToken, record.id)
          .catch(() => {
            onError();
          });

        setSavingUnsavedRecordId(undefined);
        setTableLoading(false);

        await reloadData();
      }
    },
    [
      accessToken,
      dataItems,
      onError,
      reloadData,
      setSavingUnsavedRecordId,
      setTableLoading,
    ]
  );

  const loadRecords = useCallback(async () => {
    setTableLoading(true);

    const response = (await globalManualAdjustmentsService.getRecords(
      accessToken
    )) as T[];

    setDataItems(response);
    setTableLoading(false);
  }, [accessToken, setDataItems, setTableLoading]);

  const deleteUnsavedRecord = useCallback(() => {
    if (dataItems) {
      setDataItems(dataItems.filter((item) => item.id > 0));
      setUnsavedRecord(undefined);
    }
  }, [dataItems, setDataItems, setUnsavedRecord]);

  const handleAddClick = useCallback(() => {
    if (dataItems) {
      if (dataItems.findIndex((x) => x.id === -1) === -1) {
        const newRecord: T = {
          id: -1,
        } as T;

        setDataItems([...[newRecord], ...dataItems]);
        setUnsavedRecord(newRecord);
      }
    }
  }, [dataItems, setDataItems, setUnsavedRecord]);

  const createRecord = useCallback(
    async (data: T) => {
      setTableLoading(true);
      setSavingUnsavedRecordId(data.id);

      await globalManualAdjustmentsService
        .createRecord(accessToken, data)
        .catch(() => {
          onError();
        });

      await reloadData();

      setSavingUnsavedRecordId(undefined);
      setUnsavedRecord(undefined);
      setTableLoading(false);
    },
    [
      accessToken,
      onError,
      reloadData,
      setSavingUnsavedRecordId,
      setTableLoading,
      setUnsavedRecord,
    ]
  );

  const deactivateAlert = useCallback(
    async (data: T, alertId: number) => {
      setTableLoading(true);

      await globalManualAdjustmentsService
        .deactivateRecordAlert(accessToken, data.id, alertId)
        .catch(() => {
          onError();
        });

      await reloadData();

      setTableLoading(false);
    },
    [accessToken, onError, reloadData, setTableLoading]
  );

  const onExportClick = useCallback(
    async (filteredList: T[]) => {
      let filteredIds: number[] = [];

      filteredList.forEach((item) => {
        filteredIds.push(item.id);
      });

      await globalManualAdjustmentsService
        .downloadGlobalAdjustmentData(accessToken, filteredIds)
        .catch(() => {
          onError();
        });
    },
    [accessToken, onError]
  );

  return {
    saveRecord,
    deleteRecord,
    loadRecords,
    deleteUnsavedRecord,
    handleAddClick,
    reloadData,
    createRecord,
    deactivateAlert,
    onExportClick,
    duplicateRecord,
  };
}
