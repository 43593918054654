import { Collapse, Flex, Space } from "antd";
import Title from "antd/es/typography/Title";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { aliasService } from "src/core/services/priceConfiguratorServices/aliasService";
import { AliasComment } from "src/modules/PriceConfiguratorManager/components/DataSectionAliasHandler/components/AliasComment";
import { AliasSelector } from "src/modules/PriceConfiguratorManager/components/DataSectionAliasHandler/components/AliasSelector";
import { ModelSkuSelector } from "src/modules/PriceConfiguratorManager/components/DataSectionAliasHandler/components/ModelSkuSelector/ModelSkuSelector";
import { FrameAliasesContextProvider } from "src/modules/PriceConfiguratorManager/components/DataSectionAliasHandler/context/FrameAliasesContext";
import { BomDataTable } from "src/modules/PriceConfiguratorManager/tables/BomDataTable/BomDataTable";
import { RouteDataTable } from "src/modules/PriceConfiguratorManager/tables/RouteDataTable/RouteDataTable";
import { currentPriceConfiguratorState } from "src/recoil/atoms";
import {
  DataSectionAliasData,
  DataSectionData,
  ModelSkuData,
} from "src/types/priceConfigurator";

type Props = {
  dataSection: DataSectionData;
  aliasData: DataSectionAliasData;
};

export const FrameAliases: React.FC<Props> = (props: Props) => {
  const { dataSection, aliasData } = props;
  const { accessToken, success, authLoaded } = useAuth();
  const { t } = useTranslation();

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );

  const [loading, setLoading] = useState(true);

  const [aliasId, setAliasId] = useState<number>(aliasData?.id ?? 0);

  const [modelNumber, setModelNumber] = useState<string>(
    aliasData?.modelNumber ?? ""
  );
  const [modelRelease, setModelRelease] = useState<string>(
    aliasData?.release ?? ""
  );

  const [modelSkuData, setModelSkuData] = useState<ModelSkuData | undefined>({
    modelSkuKey: aliasData.modelSkuKey,
    sku: aliasData.sku ?? "",
    costLevel: aliasData.costLevel,
  });
  const [isPackaging, setIsPackaging] = useState(
    aliasData?.isPackaging ?? true
  );

  const isInitialMount = useRef(true);

  useEffect(() => {
    (async () => {
      setLoading(false);
    })();
  }, [dataSection.sectionType]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        if (isInitialMount.current) {
          isInitialMount.current = false;
        } else {
          if (modelNumber && modelRelease) {
            if (aliasId) {
              await aliasService.updateDataSectionAlias(
                accessToken,
                aliasId,
                currentPriceConfigurator?.id ?? 0,
                dataSection.id,
                {
                  modelNumber: modelNumber,
                  release: modelRelease,
                }
              );
            } else {
              const response = await aliasService.saveDataSectionAlias(
                accessToken,
                currentPriceConfigurator?.id ?? 0,
                dataSection.id,
                {
                  modelNumber: modelNumber,
                  release: modelRelease,
                }
              );

              setAliasId(response.id);
            }
          }
        }
      }
    })();
  }, [
    accessToken,
    aliasId,
    authLoaded,
    currentPriceConfigurator?.id,
    dataSection.id,
    modelNumber,
    modelRelease,
    success,
  ]);

  return (
    <FrameAliasesContextProvider>
      <Space
        direction="vertical"
        size={0}
        style={{ marginBottom: 50 }}
        className="full-width-space"
      >
        <Title level={4} style={{ marginBottom: 0 }}>
          {t("dataSection.skuTitle")}
        </Title>
        <Flex gap="middle" align="start" justify="space-evenly">
          <AliasSelector
            disabled={currentPriceConfigurator?.isReadOnlyMode}
            modelNumberValue={modelNumber}
            setModelNumberValue={useCallback(
              (value: string) => {
                setModelRelease("");
                setModelSkuData(undefined);
                setModelNumber(value);
              },
              [setModelRelease, setModelSkuData, setModelNumber]
            )}
            releaseValue={modelRelease}
            setReleaseValue={useCallback(setModelRelease, [setModelRelease])}
            onModelNotExists={useCallback(async () => {
              // alias reset
              if (aliasId > 0) {
                await aliasService.resetDataSectionAlias(
                  accessToken,
                  aliasId,
                  currentPriceConfigurator?.id ?? 0,
                  dataSection.id,
                  { modelNumber: modelNumber, isPackaging: isPackaging }
                );
              }
            }, [
              accessToken,
              aliasId,
              currentPriceConfigurator?.id,
              dataSection.id,
              isPackaging,
              modelNumber,
            ])}
          />
          {!!aliasId && (
            <AliasComment
              disabled={currentPriceConfigurator?.isReadOnlyMode}
              aliasId={aliasId}
              priceConfiguratorId={currentPriceConfigurator.id}
              label={`${t("dataSection.aliasComments")}`}
            />
          )}
        </Flex>
        {modelRelease && (
          <ModelSkuSelector
            disabled={currentPriceConfigurator?.isReadOnlyMode}
            priceConfiguratorId={currentPriceConfigurator?.id ?? 0}
            dataSectionId={dataSection.id}
            aliasId={aliasId}
            modelNumber={modelNumber}
            modelRelease={modelRelease}
            modelSkuData={modelSkuData}
            setModelSkuData={async (data: ModelSkuData) => {
              await aliasService.updateDataSectionAliasSku(
                accessToken,
                aliasId,
                currentPriceConfigurator?.id ?? 0,
                dataSection.id,
                {
                  sku: data.sku,
                  costLevel: data.costLevel,
                }
              );

              setModelSkuData(data);
            }}
            isPackaging={isPackaging}
            onIsPackagingChange={async (isPackaging: boolean) => {
              await aliasService.updateDataSectionAliasSku(
                accessToken,
                aliasId,
                currentPriceConfigurator?.id ?? 0,
                dataSection.id,
                {
                  sku: undefined,
                  costLevel: undefined,
                  isPackaging: isPackaging,
                }
              );

              setIsPackaging(isPackaging);
              setModelSkuData(undefined);
            }}
          />
        )}
        {modelSkuData && (
          <Collapse
            defaultActiveKey={["bom-table", "routing-table"]}
            ghost
            items={[
              {
                key: "routing-table",
                label: (
                  <Title level={4} style={{ marginBottom: 0 }}>
                    {t("dataSection.bomTitle")}
                  </Title>
                ),
                children: (
                  <React.Fragment>
                    <Title
                      level={5}
                      style={{ marginBottom: 10, fontWeight: 400 }}
                    >
                      {t("label.sku")}: {modelSkuData.sku}
                    </Title>
                    <BomDataTable
                      disabled={currentPriceConfigurator?.isReadOnlyMode}
                      loading={loading}
                      skuValue={modelSkuData.sku ?? ""}
                      costLevel={modelSkuData.costLevel}
                      aliasId={aliasId}
                      priceConfiguratorId={currentPriceConfigurator?.id ?? 0}
                      dataSection={dataSection}
                    />
                  </React.Fragment>
                ),
              },
              {
                key: "bom-table",
                label: (
                  <Title level={4} style={{ marginBottom: 0 }}>
                    {t("dataSection.routingTitle")}
                  </Title>
                ),
                children: (
                  <React.Fragment>
                    <Title
                      level={5}
                      style={{ marginBottom: 10, fontWeight: 400 }}
                    >
                      {t("label.sku")}: {modelSkuData.sku}
                    </Title>
                    <RouteDataTable
                      disabled={currentPriceConfigurator?.isReadOnlyMode}
                      loading={loading}
                      skuValue={modelSkuData.sku ?? ""}
                      costLevel={modelSkuData.costLevel}
                      aliasId={aliasId}
                      priceConfiguratorId={currentPriceConfigurator?.id ?? 0}
                      dataSection={dataSection}
                    />
                  </React.Fragment>
                ),
              },
            ]}
          />
        )}
      </Space>
    </FrameAliasesContextProvider>
  );
};
