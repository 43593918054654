import { Button, Skeleton, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppFeature } from "src/core/hooks/useAppFeature";
import { useAuth } from "src/core/hooks/useAuth";
import { administrationService } from "src/core/services/administrationService";
import { sleep } from "src/core/utils/common";
import { AppFeatureEnum } from "src/enums";

export const RefreshGlobalManualAdjustmentAlertsFeature: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [longRunningRequest, setLongRunningRequest] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);

  useAppFeature(AppFeatureEnum.ADMINISTRATIONFEATURE);

  const { t } = useTranslation("administration");

  const { success, authLoaded, accessToken } = useAuth();

  useEffect(() => {
    if (authLoaded && success) {
      setLoading(false);
    }
  }, [authLoaded, success]);

  const showInfoMessage = async (): Promise<void> => {
    await sleep(6000);

    setLongRunningRequest(true);
  };

  const onSubmit = async () => {
    setIsPending(true);
    setLongRunningRequest(false);
    setCompleted(false);

    const result = await Promise.all([
      showInfoMessage(),
      administrationService
        .refreshGlobalManualAdjustmentAlerts(accessToken)
        .catch(() => {
          //ignore
        })
        .then(() => {
          setIsPending(false);
          setLongRunningRequest(false);
          setCompleted(true);
        }),
    ]);

    if (result) {
      setIsPending(false);
      setLongRunningRequest(false);
      setCompleted(true);
    }
  };

  return (
    <Skeleton loading={loading} active>
      <Space direction="vertical" size={20} style={{ width: 400 }}>
        <Typography.Title level={4}>
          {t("refreshGlobalManualAdjustmentAlertsFeature.title")}
        </Typography.Title>
        <Space
          align="end"
          style={{ width: "100%", justifyContent: "end" }}
          direction="vertical"
        >
          <Button
            disabled={isPending}
            type="primary"
            loading={isPending}
            onClick={onSubmit}
          >
            {t("refreshGlobalManualAdjustmentAlertsFeature.submitButton")}
          </Button>
          {longRunningRequest && isPending && (
            <Typography.Text>{t("common.longRunningRequest")}</Typography.Text>
          )}
          {completed && (
            <Typography.Text style={{ color: "green", fontWeight: 600 }}>
              {t("refreshGlobalManualAdjustmentAlertsFeature.completed")}
            </Typography.Text>
          )}
        </Space>
      </Space>
    </Skeleton>
  );
};
