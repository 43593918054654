import { API_BASE_URL } from "src/constants";
import { destroy, get, patch, post, postBlob } from "src/core/utils/api";
import { downloadFileFromBlob } from "src/core/utils/file";
import { GlobalManualAdjustmentItem } from "src/modules/GlobalManualAdjustmentsManager/types/types";
import { IntValue, ThresholdEntityData } from "src/types/common";

const createGlobalManualAdjustmentsService = () => {
  const getRecord = async (
    accessToken: string,
    id: number
  ): Promise<GlobalManualAdjustmentItem> => {
    return await get<GlobalManualAdjustmentItem>(
      accessToken,
      API_BASE_URL + `/v1/global-manual-adjustments/${id}`
    );
  };

  const getRecords = async (
    accessToken: string
  ): Promise<GlobalManualAdjustmentItem[]> => {
    return await get<GlobalManualAdjustmentItem[]>(
      accessToken,
      API_BASE_URL + `/v1/global-manual-adjustments`
    );
  };

  const saveRecord = async (
    accessToken: string,
    data: ThresholdEntityData
  ): Promise<GlobalManualAdjustmentItem[]> => {
    return await patch<GlobalManualAdjustmentItem[]>(
      accessToken,
      API_BASE_URL + `/v1/global-manual-adjustments`,
      data
    );
  };

  const createRecord = async (
    accessToken: string,
    data: GlobalManualAdjustmentItem
  ): Promise<IntValue> => {
    return await post<IntValue>(
      accessToken,
      API_BASE_URL + `/v1/global-manual-adjustments`,
      data
    );
  };

  const deleteRecord = async (
    accessToken: string,
    id: number
  ): Promise<void> => {
    await destroy<void>(
      accessToken,
      API_BASE_URL + `/v1/global-manual-adjustments/${id}`
    );
  };

  const duplicateRecord = async (
    accessToken: string,
    id: number
  ): Promise<void> => {
    await post<void>(
      accessToken,
      API_BASE_URL + `/v1/global-manual-adjustments/duplicate`,
      { value: id }
    );
  };

  const deactivateRecordAlert = async (
    accessToken: string,
    id: number,
    alertId: number
  ): Promise<void> => {
    await destroy<void>(
      accessToken,
      API_BASE_URL + `/v1/global-manual-adjustments/${id}/alerts/${alertId}`
    );
  };

  const downloadGlobalAdjustmentData = async (
    accessToken: string,
    ids: number[]
  ): Promise<void> => {
    const response = await postBlob<any>(
      accessToken,
      API_BASE_URL + `/v1/global-manual-adjustments/export`,
      { values: ids }
    );

    downloadFileFromBlob(
      `ManualAdjustment-${new Date().toISOString().split("T")[0]}`,
      response
    );
  };

  return {
    getRecord,
    getRecords,
    saveRecord,
    deleteRecord,
    createRecord,
    deactivateRecordAlert,
    downloadGlobalAdjustmentData,
    duplicateRecord,
  };
};

export const globalManualAdjustmentsService =
  createGlobalManualAdjustmentsService();
